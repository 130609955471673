<template>
  <v-layout class="flex-column" justify-center>
    <div>
      <div class="d-flex align-center">
        <span class="material-symbols-rounded"> description </span>
        <span class="bold h5 ml-3">Simulaciones</span>
      </div>
    </div>

    <div v-if="loading">
      <v-skeleton-loader
        v-for="index in 3"
        :key="index"
        class="rounded-lg mb-2 mt-4"
        type="card"
        max-height="170px"
      >
      </v-skeleton-loader>
    </div>
    <v-layout v-else-if="quotations?.length <= 0">
      <v-flex class="justify-center">
        <v-img src="@/assets/sin_cotizaciones-01.svg" max-width="250px" />
        <p class="global-pendentText text-center">
          No se encontró cotizaciones
        </p>
      </v-flex>
    </v-layout>
    <div v-else>
      <PendingCard v-for="(item, i) in quotations" :key="i" class="my-4">
        <template v-slot:number>
          <p>Nº Simulación {{ item.numero_cotizacion }}</p>
        </template>

        <template v-slot:description>
          <span class="bold" v-if="item?.obj_marca">
            {{
              `${item.obj_marca.marca_descripcion ?? ""} ${
                item.obj_modelo?.modelo_descripcion ?? ""
              }`
            }}
          </span>
          <span v-else class="bold">
            {{ item?.categoria_descripcion ?? "" }}
          </span>
        </template>
        <!-- <template v-slot:rut>
          {{ item.numero_documento | rutFormat }}
        </template> -->

        <template v-slot:detail>
          <p>{{ item.estado_descripcion }}</p>
        </template>
        <template v-slot:button>
          <v-btn
            @click="Retomar(item)"
            rounded
            block
            :loading="item.loading"
            color="primary"
            class="text-none mt-2"
          >
            Terminar simulación
          </v-btn>
        </template>
      </PendingCard>

      <p class="text-center mt-5">
        <v-btn
          color="primary"
          text
          class="text-none"
          @click="$router.push({ name: 'quotes', query: { rut: rut } })"
        >
          Ver más simulaciones <v-icon right>mdi-open-in-new</v-icon>
        </v-btn>
      </p>
    </div>
  </v-layout>
</template>

<script>
import PendingCard from "@/components/Pages-Components/Dashboard/SideBarCards.vue";
import { resumeQuote } from "@/Mixins/resumeQuote.js";

export default {
  name: "CotizacionesPendientesCliente",
  mixins: [resumeQuote],
  props: {
    quotations: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    rut: {
      type: String,
      default: "",
    },
  },
  components: {
    PendingCard,
  },
};
</script>
