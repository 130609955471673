<template>
  <v-card class="overflow-hidden" elevation="1" :height="cardheight">
    <v-card-text class="pa-4 py-8">
      <div class="d-flex">
        <div>
          <v-icon>mdi-account-circle</v-icon>
        </div>
        <div class="d-flex flex-column ml-2">
          <h5 class="text-capitalize">
            {{ userData?.cliente?.razon_social ?? "" }}
          </h5>

          <div class="d-flex">
            <h6 class="bold">Tipo doc.:</h6>
            <span class="h6 ml-2">{{
              userData?.cliente?.tipo_documento ?? ""
            }}</span>
          </div>

          <div class="d-flex">
            <h6 class="bold">N°:</h6>
            <span class="h6 ml-2">{{
              userData?.cliente?.numero_documento ?? ""
            }}</span>
          </div>

          <div class="d-flex">
            <h6 class="bold">Teléfono:</h6>
            <span class="h6 ml-2">{{
              userData?.contacto?.datos_contacto?.telefono ?? ""
            }}</span>
          </div>
          <div class="d-flex">
            <h6 class="bold">Correo:</h6>
            <div class="h6 ml-2">
              {{ userData?.contacto?.datos_contacto?.email ?? "" }}
            </div>
          </div>
          <div class="d-flex">
            <h6 class="bold">Cliente desde:</h6>
            <span class="h6 ml-2">{{
              userData?.fecha_creacion ?? "" | formatDate
            }}</span>
          </div>
        </div>
      </div>
    </v-card-text>
    <v-card-actions v-if="editClient" class="justify-center px-8">
      <v-btn
        rounded
        color="primary"
        class="text-none"
        block
        outlined
        @click="editUserBtn()"
      >
        <v-icon left>mdi-pencil</v-icon>
        Editar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    userData: {
      type: [Object, Array],
      default: null,
    },
    editClient: {
      type: Boolean,
      default: true,
    },
    cardheight: {
      type: Number,
      default: 305,
    },
  },
  methods: {
    editUserBtn() {
      this.$emit("edit");
    },
  },
};
</script>
