<template>
  <v-dialog
    :value="value"
    persistent
    max-width="500px"
    style="border-radius: 15px"
  >
    <v-card class="pb-6 pt-3">
      <v-card-title>
        <span class="h6 bold">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <v-alert
          :color="typeSelector[type]?.alertColor ?? ''"
          border="left"
          text
          :type="typeSelector[type]?.alertColor ?? 'info'"
        >
          Estas a un paso de {{ typeSelector[type]?.text ?? "" }} el producto
          <b>{{ product }}</b> contratado por el cliente <b>{{ client }}</b
          >.
        </v-alert>
        <p>
          ¿Estás seguro que desea {{ typeSelector[type]?.text ?? "" }} el
          producto?
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="text-none"
          outlined
          rounded
          @click="_cancel()"
          :disabled="loading"
          height="45"
          width="180"
          >Cancelar</v-btn
        >
        <v-btn
          :color="typeSelector[type]?.btnColor ?? ''"
          class="text-none"
          height="45"
          width="200"
          rounded
          :loading="loading"
          @click="_confirm()"
          >{{ typeSelector[type]?.btnText ?? "" }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalConfirmUserProduct",
  data: () => ({
    value: false,
    title: "",
    type: "",
    client: "",
    product: "",
    loading: false,

    // Variables privadas
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),
  computed: {
    typeSelector() {
      return {
        delete: {
          text: "eliminar",
          btnText: "Eliminar producto",
          alertColor: "error",
          btnColor: "primary",
        },
        renew: {
          text: "renovar",
          btnText: "Renovar producto",
          alertColor: "warning",
          btnColor: "primary",
        },
      };
    },
  },
  methods: {
    show(opts = {}) {
      this.title = opts?.title ?? "";
      this.type = opts?.type ?? "";
      this.client = opts?.client ?? "";
      this.product = opts?.product ?? "";
      // Una vez configurado las variables, se puede abrir la modal
      this.value = true;
      // Retornar el resultado en Promise para que el usuario pueda obtener el valor de seleccion
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    _confirm() {
      this.resolvePromise(true);
    },

    _cancel() {
      this.value = false;
      this.resolvePromise(false);
    },

    _load(val = false) {
      this.loading = val;
    },

    _close() {
      this.value = false;
    },
  },
};
</script>
