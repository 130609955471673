<template>
  <base-modal v-model="value" v-if="value" @close="_confirm()" small>
    <section class="pa-6">
      <h3 class="text-center">
        <v-icon large class="mx-1">mdi-{{ icon }}</v-icon
        >{{ title }}
      </h3>
      <p class="my-6">{{ description }}</p>

      <div class="text-center">
        <v-btn
          color="primary"
          class="text-none"
          rounded
          @click="_confirm()"
          :loading="loading"
        >
          {{ buttonText }}
        </v-btn>
      </div>
    </section>
  </base-modal>
</template>

<script>
import BaseModal from "./BaseModal.vue";
export default {
  components: { BaseModal },
  data: () => ({
    value: false,
    title: "",
    description: "",
    buttonText: "",
    icon: "",
    loading: false,

    // Variables privadas
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),
  methods: {
    show(opts = {}) {
      this.title = opts.title;
      this.description = opts.description;
      this.buttonText = opts?.buttonText || "Confirmar";
      this.icon = opts?.icon || "information";
      // Una vez configurado las variables, se puede abrir la modal
      this.value = true;
      // Retornar el resultado en Promise para que el usuario pueda obtener el valor de seleccion
      return new Promise((resolve) => {
        this.resolvePromise = resolve;
      });
    },

    _confirm() {
      this.value = false;
      this.loading = true;
      this.resolvePromise(true);
    },
  },
};
</script>

<style></style>
