<template>
  <v-card elevation="2" class="invisible">
    <v-layout>
      <v-flex class="pa-2">
        <v-icon size="50">mdi-car-outline</v-icon>
        <v-img
          :src="img"
          @error="handleLogoError($event, product)"
          contain
          max-width="60"
          max-height="60"
          class="mt-3"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-flex>

      <v-flex class="flex-grow-1 pa-2">
        <h6 class="h6 bold">{{ product?.materia_asegurada ?? "" }}</h6>
        <p>{{ product?.plan ?? "" }}</p>
        <p>{{ product?.producto_descripcion ?? "" }}</p>

        <div class="mt-3">
          <v-btn
            color="primary"
            text
            small
            :disabled="product?.canceling ?? false"
            @click="$emit('click-detail', product)"
            class="text-none"
          >
            <v-icon left>mdi-eye-outline</v-icon>
            Ver más
          </v-btn>

          <v-btn
            color="primary"
            text
            small
            :disabled="cancelled"
            @click="$emit('cancel-sale', product)"
            name="boton_cancelar_producto"
            class="text-none"
          >
            <v-icon left>mdi-trash-can-outline</v-icon>
            Eliminar
          </v-btn>
        </div>
      </v-flex>

      <v-divider vertical class="my-3"></v-divider>

      <v-flex class="flex-grow-1 py-2 px-3" style="min-width: 140px">
        <v-layout column style="min-height: 120px">
          <h6 class="h6 bold">Vigencia</h6>
          <p>
            Desde: <b>{{ (product?.desde ?? "") | formatDate }}</b>
          </p>
          <p>
            Hasta: <b>{{ (product?.hasta ?? "") | formatDate }}</b>
          </p>

          <!-- <v-chip
          label
          :color="getBadgeData(product?.estado ?? '').color"
          outlined
          small
          class="mt-2"
        >
          <v-icon left>mdi-circle-small</v-icon>
          {{ getBadgeData(product?.estado ?? "").title }}</v-chip
        > -->
          <v-spacer></v-spacer>

          <!-- <v-btn
            color="primary"
            class="text-none mt-3"
            width="100%"
            height="35"
            rounded
            @click="$emit('renew', product)"
            >Renovar</v-btn
          > -->
        </v-layout>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import LogoRequestMixin from "@/Mixins/LogoRequestMixin.js";
export default {
  mixins: [LogoRequestMixin],
  name: "GoodsCard",
  props: {
    product: {
      type: Object,
      default: null,
    },
  },
  computed: {
    img() {
      return this.product?.[this.logoName];
    },
    cancelled() {
      return !(this.product?.cancelable ?? true) || this.product?.estado == 9;
    },
  },
  methods: {
    getBadgeData(status) {
      let color, title;
      switch (status) {
        case "2":
          color = "#F2994A";
          title = "En emisión";
          break;
        case "3":
          color = "#27AE60";
          title = "Vigente";
          break;
        case "9":
          color = "#EE2E22";
          title = "Anulado";
          break;

        default:
          color = "grey";
          title = "Desconocido";
          break;
      }
      return {
        color,
        title,
      };
    },
  },
};
</script>
