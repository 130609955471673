<template>
  <v-dialog
    v-model="value"
    max-width="800"
    attach="#main-container"
    @click:outside="closeModal"
  >
    <div class="d-flex justify-center">
      <v-card outlined v-if="dataSuccess" width="800">
        <v-card-title class="mt-1 ml-6 mr-2">
          Detalle Póliza
          <v-spacer></v-spacer>
          <v-icon class="global-pointer mr-3" @click="closeModal"
            >mdi-close</v-icon
          >
        </v-card-title>

        <v-card class="d-flex justify-center mt-4 mx-8" elevation="1">
          <cardPlan
            :SeguroDetails="getProposalData"
            :Cotizacion="getProposalData?.cotizacion ?? null"
          />
        </v-card>
        <v-card class="my-6 mx-8" elevation="1" style="position: relative">
          <v-overlay
            v-model="loading.dialog"
            absolute
            class="align-center justify-center"
          >
            <v-progress-circular
              color="primary"
              indeterminate
              size="64"
            ></v-progress-circular>
          </v-overlay>

          <v-card-text>
            <v-tabs v-model="tab">
              <v-tabs-slider></v-tabs-slider>
              <v-tab class="custom-transform-class text-none" href="#tab-1">
                Cobertura y exclusiones
              </v-tab>

              <!-- <v-tab class="custom-transform-class text-none" href="#tab-2"
              >Plan de pago</v-tab
            > -->

              <v-tab class="custom-transform-class text-none" href="#tab-2"
                >Documentación</v-tab
              >

              <v-tab
                class="custom-transform-class text-none"
                href="#tab-3"
                v-if="!(productDetail?.estado == 9 ?? true)"
                >Documentos pendientes</v-tab
              >
            </v-tabs>

            <!-- Card Information  -->

            <v-tabs-items v-model="tab">
              <v-tab-item value="tab-1" class="pa-4">
                <SecureDetail :secure="coberturas" />
              </v-tab-item>

              <!-- <v-tab-item value="tab-2">
              <credit
                :card="
                  productDetail?.medio_pago.numero_tarjeta || card || ''
                "
                :date="productDetail?.medio_pago?.fecha_vencimiento ?? ''"
                class="mt-3 mr-4 ml-4"
              />

              <v-card flat>
                <v-card-text>
                  <simpletable
                    :items="productDetail?.medio_pago?.plan_pago ?? ''"
                    :headers="headers_three"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item> -->

              <v-tab-item value="tab-2">
                <v-card flat id="document-detail">
                  <v-card-text>
                    <!-- REGULAR DOCUMENT TABLE  -->
                    <v-data-table
                      :headers="docHeader"
                      :items="documentos"
                      hide-default-footer
                      item-key="id"
                      :loading="loading.regularTable"
                    >
                      <template v-slot:item.date="{ item }">
                        <span>{{
                          item.fecha || item.fecha_creacion || ""
                        }}</span>
                      </template>
                      <template v-slot:item.action="{ item }">
                        <v-tooltip
                          left
                          color="primary"
                          attach="#document-detail"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-on="on"
                              v-bind="attrs"
                              :loading="item.loading"
                              color="primary"
                              small
                              fab
                              text
                              @click="downloadFile(item)"
                            >
                              <v-icon>mdi-download</v-icon>
                            </v-btn>
                          </template>
                          <span
                            >Descargar {{ item.documento_descripcion }}</span
                          >
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item
                value="tab-3"
                v-if="!(productDetail?.estado == 9 ?? true)"
              >
                <v-card flat id="document-pending">
                  <v-card-text>
                    <!-- REGULAR DOCUMENT TABLE  -->
                    <v-data-table
                      :headers="docHeader"
                      :items="documentosPendientes"
                      hide-default-footer
                      item-key="id"
                      :loading="loading.pendigTable"
                    >
                      <template v-slot:item.date="{ item }">
                        <span>{{
                          item.fecha || item.fecha_creacion || ""
                        }}</span>
                      </template>
                      <template v-slot:item.action="{ item }">
                        <v-tooltip
                          left
                          color="error"
                          attach="#document-pending"
                          v-if="item.estado === 'REC'"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-on="on" v-bind="attrs" color="error"
                              >mdi-alert-circle-outline</v-icon
                            >
                          </template>
                          <span>{{
                            item?.motivo_descripcion ??
                            "No se ha logrado procesar el archivo."
                          }}</span>
                        </v-tooltip>

                        <v-tooltip
                          left
                          color="primary"
                          attach="#document-pending"
                          v-if="item.url_archivo"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-on="on"
                              v-bind="attrs"
                              :loading="item.loading"
                              color="primary"
                              small
                              fab
                              text
                              @click="downloadFile(item)"
                            >
                              <v-icon>mdi-download</v-icon>
                            </v-btn>
                          </template>
                          <span
                            >Descargar {{ item.documento_descripcion }}</span
                          >
                        </v-tooltip>

                        <v-tooltip
                          left
                          color="primary"
                          attach="#document-pending"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-on="on"
                              v-bind="attrs"
                              :loading="item.loading_upload"
                              color="primary"
                              small
                              fab
                              text
                              :disabled="disabledUpload(item)"
                              @click="handlerAttachmentFile(item)"
                            >
                              <v-icon>mdi-attachment</v-icon>
                            </v-btn>
                          </template>
                          <span>Adjuntar {{ item.documento_descripcion }}</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>

                    <input
                      type="file"
                      name="doc-file"
                      ref="docFile"
                      v-show="false"
                      @change="handlerFileSelect"
                      accept="application/pdf"
                    />
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-card>

      <v-card class="d-flex justify-center" v-else width="800">
        <v-card-text>
          <v-alert border="left" text type="warning">
            Este seguro no fue iniciado desde mclick. Favor consultar en
            plataforma BOSS
          </v-alert>
        </v-card-text>
      </v-card>

      <v-dialog
        v-model="formDialog.show"
        scrollable
        persistent
        max-width="450px"
      >
        <v-card>
          <v-card-title primary-title> Información necesaria </v-card-title>
          <v-card-text>
            <p class="caption text-center grey--text text--darken-1">
              Agregar datos faltantes a la propuesta:
            </p>
            <v-form ref="verdeForm">
              <v-row no-gutters>
                <v-col cols="12" md="6" class="pr-1">
                  <v-text-field
                    v-model="extraForm.numero_motor"
                    dense
                    outlined
                    label="Número de motor"
                    :onkeypress="`return ${alphaNum()}`"
                    :rules="[
                      (v) => !!v || 'El número de motor es requerido.',
                      (v) => formRules.min(v, 8),
                    ]"
                    @keyup="
                      extraForm.numero_motor =
                        extraForm.numero_motor.toUpperCase()
                    "
                    maxlength="20"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pl-1">
                  <v-text-field
                    v-model="extraForm.numero_chasis"
                    dense
                    outlined
                    label="Número de chasis"
                    :onkeypress="`return ${alphaNum()}`"
                    :rules="[
                      (v) => !!v || 'El número de chasis es requerido.',
                      (v) => formRules.min(v, 17),
                    ]"
                    @keyup="
                      extraForm.numero_chasis =
                        extraForm.numero_chasis.toUpperCase()
                    "
                    maxlength="20"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pr-1">
                  <v-text-field
                    v-model="extraForm.numero_factura"
                    dense
                    outlined
                    label="Número de factura"
                    :rules="[
                      (v) => !!v || 'El número de factura es requerido.',
                    ]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pl-1">
                  <v-menu
                    ref="menu1"
                    v-model="dateMenu"
                    close-on-content-click
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    name="RequirementsDate"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="extraForm.fecha_factura"
                        dense
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        label="Fecha de factura"
                        :rules="[
                          (v) => !!v || 'El campo fecha factura es requerido.',
                        ]"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="extraForm.fecha_factura"
                      min="1950-01-01"
                      name="picker_fecha_factura"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="_cancel()">Cancelar</v-btn>
            <v-btn color="primary" @click="_confirm()">Cargar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import credit from "@/components/Cards/Credit-card";
import { fileToBase64 } from "@/services/file.js";
import cardPlan from "@/components/Cards/Plan-card";
import SecureDetail from "@/components/Cards/Secure-detail-card.vue";
import { validarMinStringLimit } from "@/store/resources/validate.js";
import { alphaNumericKeys } from "@/store/resources/calc.js";
// Mixins
import DocumentMixin from "@/Mixins/DocumentsMixin.js";
export default {
  name: "DetalleProducto",
  mixins: [DocumentMixin],
  components: {
    // credit,
    cardPlan,
    SecureDetail,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
      description: "Abrir/Cerrar Modal",
    },
    rut: {
      type: String,
      default: "",
      description: "RUT del cliente",
    },
    productDetail: {
      type: Object,
      default: null,
      required: true,
      description: "Objeto del detalle del producto",
    },
  },

  created() {
    this.setup();
  },
  data() {
    return {
      tab: "tab-1",
      loading: {
        dialog: false,
        pendigTable: false,
      },
      rowSelected: null,
      formDialog: {
        show: false,
        resolvePromise: undefined,
        rejectPromise: undefined,
      },
      form: {
        numero_propuesta: null,
        tipo_documento: "",
        documento: "",
        documento_descripcion: "",
        nombre_archivo: "",
        tipo_archivo: "",
      },
      extraForm: {
        numero_chasis: "",
        numero_motor: "",
        numero_factura: "",
        fecha_factura: "",
      },
      formRules: {
        min: validarMinStringLimit,
      },
      ventaVerde: false,
      dateMenu: false,
      // headers_three: [
      //   {
      //     text: "Cuota",
      //     value: "cuota",
      //     width: "10%",
      //   },
      //   {
      //     text: "Monto",
      //     value: "monto_moneda_local",
      //     width: "10%",
      //   },
      //   {
      //     text: "Fecha",
      //     value: "fecha_pago",
      //     align: "center",
      //     width: "12%",
      //   },
      //   {
      //     text: "Estado",
      //     value: "estado_nombre",
      //     align: "center",
      //     width: "9%",
      //   },
      // ],
      docHeader: [
        {
          text: "Nombre",
          value: "archivo_descripcion",
          align: "left",
          sortable: true,
        },
        {
          text: "Tipo",
          value: "documento_descripcion",
          align: "left",
          sortable: true,
        },
        {
          text: "Estado",
          value: "estado_descripcion",
          align: "center",
          sortable: true,
        },
        {
          text: "Fecha",
          value: "date",
          align: "center",
          sortable: true,
        },
        {
          text: "",
          value: "action",
          align: "right",
          sortable: false,
        },
      ],
      dataSuccess: true,
    };
  },
  computed: {
    ...mapGetters("Proposal", [
      "documentos",
      "documentosPendientes",
      "coberturas",
      "getProposalData",
    ]),

    alphaNum: () => alphaNumericKeys,
  },
  methods: {
    ...mapActions("Proposal", ["findProposalDocuments"]),
    ...mapActions("Documents", ["UploadDoc"]),

    setup() {
      const nroPropuesta = this.productDetail?.numero_propuesta;
      if (nroPropuesta) {
        this.loading.dialog = true;

        this.form.numero_propuesta = nroPropuesta;
        this.findProposalDocuments(nroPropuesta)
          .then((response) => {
            this.dataSuccess = response.success;
            this.ventaVerde = response.data?.tipo_venta === "V";
          })
          .catch((message) => {
            this.$store.commit("setSnackbar", {
              active: true,
              text: message,
              top: true,
              right: true,
              color: "error",
            });
          })
          .finally(() => (this.loading.dialog = false));
      } else {
        this.$store.commit("setSnackbar", {
          active: true,
          text: "No se ha encontrado el numero de propuesta",
          top: true,
          right: true,
          color: "error",
        });
      }
    },
    handlerAttachmentFile(selected) {
      this.rowSelected = selected;

      this.form.tipo_documento =
        selected.documento || (selected.tipo_documento ?? "");
      this.form.documento_descripcion = selected.documento_descripcion ?? "";

      this.$refs.docFile.click();
    },
    async handlerFileSelect(e) {
      if (e.target?.files?.length > 0) {
        this.rowSelected.loading_upload = true;
        const file = e.target.files[0];
        // Verificar pdf
        if (file.type !== "application/pdf") {
          this.$store.commit("setSnackbar", {
            active: true,
            text: "Debe cargar un archivo de tipo PDF",
            top: true,
            right: true,
            color: "warning",
          });
          this.resetFile();
          return false;
        }
        // Transformar a base64
        const base64File = await fileToBase64(file);
        this.form.documento = base64File.split("base64,")[1];
        this.form.nombre_archivo = file.name;
        this.form.tipo_archivo = file.type;

        // Condicion de venta en verde
        if (this.ventaVerde) {
          const checkExtraData = await this.showExtraForm();
          if (checkExtraData) {
            this._close();
            const formData = { ...this.form, ...this.extraForm };
            this.uploadFile(formData);
          } else {
            this.resetFile();
          }
        } else {
          this.uploadFile(this.form);
        }
      } else {
        this.resetFile();
      }
    },

    // Cargar el archivo
    uploadFile(form = {}) {
      this.UploadDoc(form)
        .then((response) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: response.info,
            top: true,
            right: true,
            color: "success",
          });
          this.setup();
        })
        .catch((message) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: message,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => this.resetFile());
    },

    resetFile() {
      if (this.rowSelected?.loading_upload) {
        this.rowSelected.loading_upload = false;
      }
      this.form.tipo_documento = "";
      this.form.documento = "";
      this.form.documento_descripcion = "";
      this.form.nombre_archivo = "";
      this.form.tipo_archivo = "";
      this.$refs.docFile.value = "";

      this.extraForm.fecha_factura = "";
      this.extraForm.numero_chasis = "";
      this.extraForm.numero_motor = "";
      this.extraForm.numero_factura = "";
    },
    closeModal() {
      this.$emit("close");
    },

    // Extra form methods
    showExtraForm() {
      // Una vez configurado las variables, se puede abrir la modal
      this.formDialog.show = true;
      // Retornar el resultado en Promise para que el usuario pueda obtener el valor de seleccion
      return new Promise((resolve, reject) => {
        this.formDialog.resolvePromise = resolve;
        this.formDialog.rejectPromise = reject;
      });
    },

    _confirm() {
      const checkForm = this.$refs.verdeForm.validate();
      if (checkForm) {
        this.formDialog.resolvePromise(true);
      }
    },

    _close() {
      this.formDialog.show = false;
    },

    _cancel() {
      this.formDialog.show = false;
      this.formDialog.resolvePromise(false);
    },
  },
};
</script>
