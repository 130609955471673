<template>
  <div>
    <ScrollSidebar>
      <template v-slot:content>
        <v-row no-gutters>
          <v-col cols="12">
            <v-col xs="12" class="d-flex py-0 my-0">
              <!-- BOTON VOLVER AL INICIO -->
              <v-btn
                color="primary"
                text
                @click="goBack"
                v-if="showBackHome"
                class="text-none"
              >
                <v-icon left>mdi-arrow-left-bold</v-icon>
                Volver a buscar</v-btn
              >

              <!-- BOTON NUEVA COTIZACION -->
              <template v-if="showNewQuoteButton">
                <v-spacer></v-spacer>

                <router-link
                  :to="{
                    name: 'NewQuotation',
                    params: { document_number, document_type },
                  }"
                >
                  <v-btn
                    color="primary"
                    class="px-10 text-none"
                    rounded
                    name="boton_nueva_cotizacion"
                  >
                    <span> Nueva simulación </span>
                  </v-btn>
                </router-link>
              </template>
            </v-col>

            <!-- OFERTAS -->
            <!-- <v-col xs="12" md="12" v-if="getOffers && getOffers.length > 0">
              <Offers :offers="getOffers" />
            </v-col> -->

            <v-col xs="12" class="mt-9">
              <v-row>
                <!-- TITULO DETALLE DEL CLIENTE -->
                <v-col xs="12" md="5">
                  <h4 class="bold">Detalle del cliente</h4>
                </v-col>
                <!-- TITULO PRODUCTOS CONTRATADOS -->
                <v-col xs="12" md="7" v-if="showBuyedProducts">
                  <div class="d-flex justify-space-between">
                    <div class="flex-grow-1 flex-shrink-0">
                      <h4 class="bolf">Productos contratados</h4>
                    </div>
                    <div class="w-45">
                      <SelectLabel
                        :fields="filterProducts"
                        color="#0077c8"
                        hide-details
                        name="filtro_productos"
                      />
                    </div>
                  </div>
                </v-col>
              </v-row>

              <v-row class="mt-0 py-0">
                <!-- TARJETA DE INFO CLIENTE -->
                <v-col xs="12" md="5">
                  <template v-if="!loading.clientData">
                    <template v-if="isChile">
                      <CardUser v-bind="userBindInfo" @edit="goEditClient()" />
                    </template>
                    <template v-else-if="isPeru">
                      <CardUserJURPeru
                        v-if="getUserInfo?.tipo_persona == 'JUR'"
                        v-bind="userBindInfo"
                        @edit="goEditClient()"
                      />
                      <CardUserNATPeru
                        v-else
                        v-bind="userBindInfo"
                        @edit="goEditClient()"
                      />
                    </template>
                  </template>
                  <!-- Cargando info de cliente -->
                  <v-skeleton-loader
                    v-else
                    class="rounded-lg"
                    type="list-item-avatar, image, actions"
                  ></v-skeleton-loader>
                </v-col>
                <!-- TARJETA PRODUCTOS CONTRATADOS -->
                <v-col xs="12" md="7" v-if="showBuyedProducts">
                  <v-card
                    class="justify-center align-center bordered py-5 px-3 h-100"
                    elevation="1"
                    min-height="275"
                    max-height="305"
                  >
                    <div class="card-content px-3 py-2 overflow-y-auto">
                      <v-fade-transition mode="out-in">
                        <div v-if="loading.userProduct">
                          <v-skeleton-loader
                            v-for="x in 3"
                            :key="x"
                            type="list-item-avatar-three-line"
                          ></v-skeleton-loader>
                        </div>
                        <div
                          v-else-if="
                            getProducts(filterProducts.selected).length > 0
                          "
                        >
                          <ProductsUser
                            :products="getProducts(filterProducts.selected)"
                            :rut="document_number"
                            @renew="renewSale($event)"
                            @cancel-sale="cancelSale($event)"
                            @see-more="
                              (productSelected = $event),
                                (dialog.product = true)
                            "
                          />
                        </div>
                        <div
                          v-else
                          class="d-flex justify-center flex-column text-center"
                        >
                          <v-icon large>mdi-magnify</v-icon>
                          <h4 class="semiBold my-5">
                            No se ha encontrado productos contratados.
                          </h4>
                          <span class="h4"
                            >Prueba cambiando el filtro del estado del
                            producto.</span
                          >
                        </div>
                      </v-fade-transition>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-col>
        </v-row>

        <v-fade-transition>
          <ProductDetail
            v-if="productSelected && dialog.product"
            v-model="dialog.product"
            :rut="document_number"
            :productDetail="productSelected"
            @close="dialog.product = false"
          />
        </v-fade-transition>

        <confirm-dialog ref="confirmDialog" />
        <info-modal ref="infoDialog" />
      </template>
      <template v-slot:sidebar>
        <!-- DOCUMENTOS Y COTIZACIONES PENDIENTES DEL CLIENTE -->
        <Pendings
          :show-docs="showPendingDocs"
          :show-quotes="showPendingQuotes"
          :loading-docs="loading.documents"
          :loading-quotes="loading.quotes"
          :quotations="Cotizaciones"
          :documents="Documentos"
          :user="getUserInfo"
        />
      </template>
    </ScrollSidebar>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import CardUser from "@/components/Cards/ClientDetail/Client-detail";
import CardUserNATPeru from "@/components/Cards/ClientDetail/Client-detail-nat-peru.vue";
import CardUserJURPeru from "@/components/Cards/ClientDetail/Client-detail-jur-peru.vue";

import ScrollSidebar from "@/layouts/ScrollSidebar.vue";
// import Offers from "@/components/User-Components/OfferUser.vue";
import Pendings from "@/components/User-Components/Pending/Pendings.vue";
import ProductsUser from "@/components/User-Components/ProductsUser";
import ProductDetail from "@/components/Modal/ClientDetail/Modal-Product-detail.vue";

import ConfirmDialog from "@/components/Modal/ClientDetail/ModalUserProductConfirm.vue";
import InfoModal from "@/components/Modal/infoModal.vue";

import SelectLabel from "@/components/Inputs/SelectLabel.vue";
import CountryMixin from "@/Mixins/CountryMixin.js";
import axios from "axios";
export default {
  name: "DetalleDelCliente",
  mixins: [CountryMixin],
  props: {
    document_number: {
      type: String,
      default: "",
    },
    document_type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      filterProducts: {
        label: "Estado",
        answers: [
          { label: "Todos", value: "" },
          { label: "Cancelado", value: "9" },
          { label: "En emisión", value: "2" },
          { label: "Vigente", value: "3" },
        ],
        selected: "3",
        placeholder: "Estado",
      },
      loading: {
        clientData: false,
        userProduct: false,
        documents: false,
        quotes: false,
      },
      productSelected: null,
      dialog: {
        product: false,
        productRenew: false,
        productCancel: false,
      },
      nroProp: null,
    };
  },
  components: {
    CardUser,
    CardUserNATPeru,
    CardUserJURPeru,
    // Offers,
    ProductsUser,
    ProductDetail,
    SelectLabel,
    Pendings,
    ScrollSidebar,
    ConfirmDialog,
    InfoModal,
  },
  created() {
    if (!this.document_number || !this.document_type) {
      // Si no se pasa el rut por cabecera, regresa al dashboard
      this.$store.commit("setSnackbar", {
        active: true,
        text: "No se ha establecido un numero de documento para el cliente.",
        top: true,
        right: true,
        color: "error",
      });
      this.goBack();
    } else {
      this.loadData();
    }
  },
  methods: {
    ...mapActions("Users", ["setClient", "findProductsUser"]),
    ...mapActions("Documents", ["GetDocs"]),
    ...mapActions("Quot", ["GetCotizaciones"]),

    goBack() {
      this.$router.push({ name: "dashboard" });
    },

    async loadData() {
      this.setQuotes();
      this.setDocuments();
      this.setClientData();
      // this.findOffersUser(rut);
      this.setUserProducts();
    },

    goEditClient() {
      this.$router.push({
        name: "NewEditUser",
        params: {
          document_number: this.document_number,
          document_type: this.document_type,
        },
      });
    },

    // === Establecer los datos del cliente ===
    setClientData() {
      this.loading.clientData = true;
      const document_number =
        this.document_number ||
        this.getUserInfo?.cliente?.numero_documento ||
        "";
      const document_type =
        this.document_type || this.getUserInfo?.cliente?.tipo_documento || "";
      this.setClient({ document_number, document_type })
        .then((response) => {
          let editUser = false,
            title = "",
            description = "";
          if (!response.comuna) {
            editUser = true;
            title = "Información necesaria";
            description =
              "Debe agregar una comuna a este cliente para continuar la simulación.";
          }
          if (response.policy) {
            editUser = true;
            title = "Validación de Políticas";
            description =
              "Antes de continuar debe primero aceptar nuestras políticas de privacidad.";
          }

          if (editUser) {
            this.$refs.infoDialog
              .show({
                title,
                description,
                buttonText: "¡Entendido!",
              })
              .then(() => {
                this.goEditClient();
              });
          }
        })
        .catch(() => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: "Ha ocurrido un error al intentar recuperar la informacion del cliente.",
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => {
          this.loading.clientData = false;
        });
    },

    // === Establecer los productos contratados del cliente ===
    setUserProducts() {
      if (this.showBuyedProducts) {
        this.loading.userProduct = true;

        const nroProp = {
          nroProp:
            this.$route.params?.numero_propuesta ||
            sessionStorage.getItem("proposal-number-forum") ||
            undefined,
          nroDoc: this.document_number,
        };
        this.nroProp = nroProp;

        this.findProductsUser(nroProp).finally(
          () => (this.loading.userProduct = false)
        );
      }
    },

    // ===  Establecer las cotizaciones del cliente ===
    setQuotes() {
      this.loading.quotes = true;
      return new Promise((resolve) => {
        this.GetCotizaciones({ limite: 5, rut: this.document_number })
          .catch((error) => {
            this.$store.commit("setSnackbar", {
              active: true,
              text: error,
              top: true,
              right: true,
              color: "error",
            });
          })
          .finally(() => (this.loading.quotes = false));
        resolve("ok");
      });
    },

    // === Establecer los documentos pendientes del cliente ===
    setDocuments() {
      this.loading.documents = true;
      return new Promise((resolve) => {
        this.GetDocs({
          cliente: this.document_number,
          limit: 5,
          estados_documentos: "PEN,REC",
        })
          .catch((error) => {
            this.$store.commit("setSnackbar", {
              active: true,
              text: error,
              top: true,
              right: true,
              color: "error",
            });
          })
          .finally(() => (this.loading.documents = false));
        resolve("ok");
      });
    },

    renewSale(item = null) {
      if (item) {
        const modal = this.$refs.confirmDialog;
        modal
          .show({
            type: "renew",
            title: "Renovar producto",
            product: item.producto_descripcion,
            client: `${this.getUserInfo?.cliente?.nombre ?? ""} ${
              this.getUserInfo?.cliente?.primer_apellido ?? ""
            }`,
          })
          .then(async (result) => {
            if (result) {
              modal._load(true);
              modal._load(false);
            }
          });
      }
    },

    cancelSale(item = null) {
      if (item) {
        const modal = this.$refs.confirmDialog;

        modal
          .show({
            type: "delete",
            title: "Eliminar producto",
            product: item.producto_descripcion,
            client: `${this.getUserInfo?.cliente?.nombre ?? ""} ${
              this.getUserInfo?.cliente?.primer_apellido ?? ""
            }`,
          })
          .then(async (result) => {
            if (result) {
              modal._load(true);

              axios({
                url: `propuestas/${item.numero_propuesta}/cancelar-poliza`,
                method: "POST",
                data: {
                  fecha_inicio_vigencia: item.desde,
                  convenio: item.convenio,
                  usuario: "tass",
                  canal: "794",
                },
              })
                .then(() => {
                  this.$store.commit("setSnackbar", {
                    active: true,
                    text: "Su proceso de cancelación terminó exitosamente",
                    top: true,
                    right: true,
                    color: "success",
                  });
                  this.setUserProducts(this.nroProp);
                  modal._close();
                })
                .catch((e) => {
                  const error = e?.response?.data;
                  let message =
                    "Ha ocurrido un error la intentar cancelar la venta";
                  if (error.data.lista_mensaje?.length > 0) {
                    message =
                      error.data.lista_mensaje[0]?.mensaje ??
                      "Venta no encontrada";
                  }
                  this.$store.commit("setSnackbar", {
                    active: true,
                    text: message,
                    top: true,
                    right: true,
                    color: "error",
                  });
                })
                .finally(() => modal._load(false));
            }
          });
      }
    },
  },

  computed: {
    ...mapGetters("Users", ["getUserInfo", "getProducts"]),
    ...mapGetters("Documents", ["Documentos"]),
    ...mapGetters("Quot", ["Cotizaciones"]),

    userBindInfo() {
      return {
        "user-data": this.getUserInfo,
        "edit-client": this.showClientEdit,
        "user-type": this.getUserInfo?.tipo_persona ?? "NAT",
        cardHeight: 305,
      };
    },

    userChannelInfo() {
      return this.$store.getters.getGeneralInfo;
    },

    // ACCESOS DE PANTALLA ------
    accesoPantallas() {
      return this.userChannelInfo?.personalizacion_corporativa
        ?.acceso_pantallas;
    },
    showClientEdit() {
      return (
        this.accesoPantallas?.find((val) => val.codigo === "EDITAR_CLIENTE")
          ?.activo ?? true
      );
    },
    showBuyedProducts() {
      return (
        this.accesoPantallas?.find(
          (val) => val.codigo === "PRODUCTOS_CONTRATADOS"
        )?.activo ?? true
      );
    },
    showPendingQuotes() {
      return (
        this.accesoPantallas?.find(
          (val) =>
            val.codigo === "COTIZACIONES_PENDIENTES" &&
            val.seccion === "DETALLE_CLIENTE"
        )?.activo ?? true
      );
    },
    showNewQuoteButton() {
      return (
        this.accesoPantallas?.find((val) => val.codigo === "SECCION_COTIZACION")
          ?.activo ?? true
      );
    },
    showPendingDocs() {
      return (
        this.accesoPantallas?.find(
          (val) =>
            val.codigo === "DOCUMENTOS_PENDIENTES" &&
            val.seccion === "DETALLE_CLIENTE"
        )?.activo ?? true
      );
    },
    showBackHome() {
      return (
        this.accesoPantallas?.find(
          (val) =>
            val.codigo === "VOLVER_INICIO" && val.seccion === "DETALLE_CLIENTE"
        )?.activo ?? true
      );
    },
    // /ACCESOS DE PANTALLA ------
  },
};
</script>

<style scoped>
.w-45 {
  width: 45%;
}

a:-webkit-any-link {
  text-decoration: none !important;
}
</style>
