<template>
  <v-layout class="flex-column" justify-center>
    <div>
      <v-card flat class="invisible">
        <div class="d-flex align-center">
          <span class="material-symbols-rounded"> folder_open </span>
          <span class="bold h5 ml-3">Documentos </span>
        </div>
      </v-card>
    </div>

    <div v-if="loading">
      <v-skeleton-loader
        v-for="index in 3"
        :key="index"
        class="rounded-lg mb-2 mt-4"
        type="card"
        max-height="170px"
      >
      </v-skeleton-loader>
    </div>
    <v-layout v-else-if="documents.length <= 0">
      <v-flex class="justify-center">
        <v-img
          class="text-center mx-auto"
          max-width="250px"
          width="250"
          src="@/assets/ilustracion_no_documentos__Artboard.png"
        />
        <p class="global-pendentText text-center">
          No hay documentos pendientes
        </p>
      </v-flex>
    </v-layout>

    <div v-else>
      <PendingCard v-for="(item, i) in documents" :key="i" height="180">
        <template v-slot:number>
          <p>Nº Propuesta {{ item.numero_cotizacion }}</p>
        </template>
        <template v-slot:detail>
          <p>{{ item.estado_descripcion }}</p>
          <div class="d-flex">
            <b
              class="pr-2"
              v-for="document in item.documentos"
              :key="document.fecha_creacion"
            >
              <p>{{ document.documento_descripcion }}</p>
            </b>
          </div>
        </template>
        <template v-slot:button>
          <v-btn
            @click="go(item)"
            rounded
            block
            color="primary"
            class="text-none mt-2"
          >
            Adjuntar documentos
          </v-btn>
        </template>
      </PendingCard>

      <p class="text-center mt-5">
        <v-btn
          color="primary"
          class="text-none"
          text
          @click="$router.push({ name: 'documents', query: { rut: rut } })"
        >
          Ver más documentos <v-icon right>mdi-open-in-new</v-icon>
        </v-btn>
      </p>
    </div>
  </v-layout>
</template>

<script>
import PendingCard from "@/components/Pages-Components/Dashboard/SideBarCards.vue";

export default {
  props: {
    documents: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    rut: {
      type: String,
      default: "",
    },
  },
  components: {
    PendingCard,
  },
  methods: {
    go(item) {
      this.$router.push({
        name: "documents",
        query: { propuesta: item.numero_propuesta, rut: this.rut },
      });
    },
  },
};
</script>
