<template>
  <v-container class="pa-0" fluid>
    <v-row no-gutters>
      <v-col
        cols="12"
        md="8"
        xl="9"
        class="pa-10"
        :order="scrollSide === 'right' ? 0 : 1"
      >
        <slot name="content"></slot>
      </v-col>
      <v-col
        cols="16"
        md="4"
        xl="3"
        class="flex-grow-1 overflow-auto global-aside-height global-sticky-sidebar"
        :order="scrollSide === 'right' ? 1 : 0"
      >
        <slot name="sidebar"></slot>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "RightScrollbarLayout",
  props: {
    scrollSide: {
      type: String,
      default: "right",
    },
  },
};
</script>
