<template>
  <div>
    <div
      v-for="(product, index) in products"
      :key="index"
      :class="[
        index == 0 ? 'pt-0' : 'pt-3',
        index + 1 === products.length ? 'pb-0' : 'pb-3',
      ]"
    >
      <ProductCard
        :product="product"
        :rut="rut"
        @click-detail="$emit('see-more', $event)"
        @renew="$emit('renew', $event)"
        @cancel-sale="$emit('cancel-sale', $event)"
      />
    </div>
  </div>
</template>
<script>
import ProductCard from "@/components/Cards/ClientDetail/Goods-card.vue";
export default {
  name: "UsersProductList",
  props: {
    products: {
      type: Array,
      default: () => [],
      description: "Listado de productos",
    },
    rut: {
      type: String,
      default: "",
      description: "RUT del cliente",
    },
  },
  components: {
    ProductCard,
  },
  methods: {
    onLoading(value) {
      this.$emit("loading", value);
    },
  },
};
</script>
