<template>
  <v-card
    elevation="0"
    class="global-cardStyle13 overflow-y-auto"
    max-height="250"
    scrollable
  >
    <div class="ml-6 mb-10">
      <v-layout
        v-for="(secureDetail, index) in secure"
        :key="index"
        class="mt-8 align-center"
      >
        <v-flex xs1>
          <v-icon class="global-iconStyle8 m-icon">bi bi-shield-check</v-icon>
        </v-flex>
        <v-flex xs11 class="mt-1">
          <h3 class="global-titleDetail">
            {{ secureDetail.titulo }}
          </h3>
          <p class="global-subDetail mt-1 mb-0">
            {{ secureDetail.descripcion }}
          </p>
        </v-flex>
      </v-layout>
    </div>
  </v-card>
</template>
<script>
export default {
  props: ["secure"],
};
</script>
<style scoped>
@import "../../assets/CSS/ServiceStyle.css";
</style>
