var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ScrollSidebar',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-col',{staticClass:"d-flex py-0 my-0",attrs:{"xs":"12"}},[(_vm.showBackHome)?_c('v-btn',{staticClass:"text-none",attrs:{"color":"primary","text":""},on:{"click":_vm.goBack}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-left-bold")]),_vm._v(" Volver a buscar")],1):_vm._e(),(_vm.showNewQuoteButton)?[_c('v-spacer'),_c('router-link',{attrs:{"to":{
                  name: 'NewQuotation',
                  params: { document_number: _vm.document_number, document_type: _vm.document_type },
                }}},[_c('v-btn',{staticClass:"px-10 text-none",attrs:{"color":"primary","rounded":"","name":"boton_nueva_cotizacion"}},[_c('span',[_vm._v(" Nueva simulación ")])])],1)]:_vm._e()],2),_c('v-col',{staticClass:"mt-9",attrs:{"xs":"12"}},[_c('v-row',[_c('v-col',{attrs:{"xs":"12","md":"5"}},[_c('h4',{staticClass:"bold"},[_vm._v("Detalle del cliente")])]),(_vm.showBuyedProducts)?_c('v-col',{attrs:{"xs":"12","md":"7"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"flex-grow-1 flex-shrink-0"},[_c('h4',{staticClass:"bolf"},[_vm._v("Productos contratados")])]),_c('div',{staticClass:"w-45"},[_c('SelectLabel',{attrs:{"fields":_vm.filterProducts,"color":"#0077c8","hide-details":"","name":"filtro_productos"}})],1)])]):_vm._e()],1),_c('v-row',{staticClass:"mt-0 py-0"},[_c('v-col',{attrs:{"xs":"12","md":"5"}},[(!_vm.loading.clientData)?[(_vm.isChile)?[_c('CardUser',_vm._b({on:{"edit":function($event){return _vm.goEditClient()}}},'CardUser',_vm.userBindInfo,false))]:(_vm.isPeru)?[(_vm.getUserInfo?.tipo_persona == 'JUR')?_c('CardUserJURPeru',_vm._b({on:{"edit":function($event){return _vm.goEditClient()}}},'CardUserJURPeru',_vm.userBindInfo,false)):_c('CardUserNATPeru',_vm._b({on:{"edit":function($event){return _vm.goEditClient()}}},'CardUserNATPeru',_vm.userBindInfo,false))]:_vm._e()]:_c('v-skeleton-loader',{staticClass:"rounded-lg",attrs:{"type":"list-item-avatar, image, actions"}})],2),(_vm.showBuyedProducts)?_c('v-col',{attrs:{"xs":"12","md":"7"}},[_c('v-card',{staticClass:"justify-center align-center bordered py-5 px-3 h-100",attrs:{"elevation":"1","min-height":"275","max-height":"305"}},[_c('div',{staticClass:"card-content px-3 py-2 overflow-y-auto"},[_c('v-fade-transition',{attrs:{"mode":"out-in"}},[(_vm.loading.userProduct)?_c('div',_vm._l((3),function(x){return _c('v-skeleton-loader',{key:x,attrs:{"type":"list-item-avatar-three-line"}})}),1):(
                          _vm.getProducts(_vm.filterProducts.selected).length > 0
                        )?_c('div',[_c('ProductsUser',{attrs:{"products":_vm.getProducts(_vm.filterProducts.selected),"rut":_vm.document_number},on:{"renew":function($event){return _vm.renewSale($event)},"cancel-sale":function($event){return _vm.cancelSale($event)},"see-more":function($event){(_vm.productSelected = $event),
                              (_vm.dialog.product = true)}}})],1):_c('div',{staticClass:"d-flex justify-center flex-column text-center"},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-magnify")]),_c('h4',{staticClass:"semiBold my-5"},[_vm._v(" No se ha encontrado productos contratados. ")]),_c('span',{staticClass:"h4"},[_vm._v("Prueba cambiando el filtro del estado del producto.")])],1)])],1)])],1):_vm._e()],1)],1)],1)],1),_c('v-fade-transition',[(_vm.productSelected && _vm.dialog.product)?_c('ProductDetail',{attrs:{"rut":_vm.document_number,"productDetail":_vm.productSelected},on:{"close":function($event){_vm.dialog.product = false}},model:{value:(_vm.dialog.product),callback:function ($$v) {_vm.$set(_vm.dialog, "product", $$v)},expression:"dialog.product"}}):_vm._e()],1),_c('confirm-dialog',{ref:"confirmDialog"}),_c('info-modal',{ref:"infoDialog"})]},proxy:true},{key:"sidebar",fn:function(){return [_c('Pendings',{attrs:{"show-docs":_vm.showPendingDocs,"show-quotes":_vm.showPendingQuotes,"loading-docs":_vm.loading.documents,"loading-quotes":_vm.loading.quotes,"quotations":_vm.Cotizaciones,"documents":_vm.Documentos,"user":_vm.getUserInfo}})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }